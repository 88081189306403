import React, { useState, useEffect } from 'react';
import {
  Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button,
  Box, Typography, Paper, TextField, Checkbox, Divider, Snackbar, Alert
} from '@mui/material';
import { fetchFilesByCourse } from '../../services/FileService';
import ENDPOINTS from '../../utils/apiConfig';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import FileList from '../../components/Lists/FileList';
import { fetchFileDetails } from '../../services/FileService';
import { useNavigate } from 'react-router-dom';


const BASE_URL = process.env.REACT_APP_API_BASE_URL;

const CreateQuizDialog = ({ open, onClose, courseId, onQuizCreated, lastUploadTimestamp, courseName }) => {
  
  const [files, setFiles] = useState([]);
  const [filesError, setFilesError] = useState(null);
  const [quizName, setQuizName] = useState('');
  const [selectedFileId, setSelectedFileId] = useState(null);
  const [selectedPages, setSelectedPages] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedImage, setSelectedImage] = useState(null);
  const [isImageOpen, setIsImageOpen] = useState(false);
  const [filePages, setFilePages] = useState([]);
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState(''); 
  const [lastSelectedIndex, setLastSelectedIndex] = useState(null);
  const [isFileReady, setIsFileReady] = useState(false);
  const navigate = useNavigate();



  // Data fetching and state updates 

  useEffect(() => {
    if (!open) {
        setQuizName('');
        setSelectedFileId(null);
        setSelectedPages([]);
        setIsFileReady(false);
        setFilePages([]);
        setErrorMessage('');
        setSuccessMessage('');
        setCurrentPage(1);
    }
  }, [open]);

  useEffect(() => {
    console.log("Selected File ID:", selectedFileId);
  }, [selectedFileId]);
  
  useEffect(() => {
    fetchFilesByCourse(courseId)
      .then(setFiles)
      .catch(error => {
        console.error('Failed to fetch files:', error);
        setFilesError('Failed to load file data');
      });
  }, [courseId, lastUploadTimestamp]); 

  useEffect(() => {
    console.log("File readiness status changed to:", isFileReady);
  
  }, [isFileReady]);
  
  const updateFileInState = (updatedFile) => {
    setFiles(prevFiles => {
        const newFiles = prevFiles.map(file => file.id === updatedFile.id ? updatedFile : file);
        console.log('Updated files:', newFiles); 
        return newFiles;
    });
};


  // Handlers for file interaction


const handleFileClick = async (file) => {
  setSelectedFileId(file.id);
  setIsFileReady(false);  
  checkFileStatusOnce(file.id);  
  setFilePages([]);
};

const checkFileStatusOnce = async (fileId) => {
  if (!fileId) return;

  try {
      const fileData = await fetchFileDetails(fileId);
      console.log("File data received:", fileData);

      if (fileData.text_status === 'completed' && fileData.images_status === 'completed') {
          setIsFileReady(true);
          console.log("File is ready for use:", fileId);
          fetchFilePages(fileId);  // Fetch pages only if file is ready
      } else {
          setIsFileReady(false);
          console.log("File is not ready:", fileId, "Status:", fileData.text_status, fileData.images_status);
      }
  } catch (error) {
      console.error('Error fetching file status:', error);
      setIsFileReady(false);
      console.log("Error during file status check:", fileId, error);
  }
};

  
  const fetchFilePages = async (fileId) => {
    try {
      const token = localStorage.getItem('accessToken');
      const response = await fetch(ENDPOINTS.GET_FILE_PAGE_SMALL_IMAGES(fileId), {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${token}`,
        }
      });
      if (response.ok) {
        const data = await response.json();
        setFilePages(data);
      } else {
        console.error('Failed to fetch file pages:', await response.text());
      }
    } catch (error) {
      console.error('Error fetching file pages:', error);
    }
  };

  const handleNextPage = () => {
    setCurrentPage(2);
  };

  const handlePreviousPage = () => {
    setCurrentPage(1);
  };

  const handlePageSelection = (index, event) => {
    if (event.shiftKey && lastSelectedIndex !== null) {
      const start = Math.min(lastSelectedIndex, index);
      const end = Math.max(lastSelectedIndex, index);
      const range = Array.from({ length: end - start + 1 }, (_, i) => start + i);
  
      setSelectedPages(prevSelectedPages => {
        const newSelectedPages = new Set(prevSelectedPages);
        range.forEach(i => newSelectedPages.add(i));
        return Array.from(newSelectedPages);
      });
    } else if (event.metaKey || event.ctrlKey) { // Check if Command key (Mac) or Ctrl key (Windows/Linux) is pressed
      setSelectedPages(prevSelectedPages =>
        prevSelectedPages.includes(index)
          ? prevSelectedPages.filter(page => page !== index)
          : [...prevSelectedPages, index]
      );
      setLastSelectedIndex(index); // Update the last selected index
    } else {
      setSelectedPages(prevSelectedPages => {
        if (prevSelectedPages.includes(index)) {
          return prevSelectedPages.filter(page => page !== index);
        } else {
          return [...prevSelectedPages, index];
        }
      });
      setLastSelectedIndex(index); // Update the last selected index
    }
  };

  const handleSelectAll = () => {
    if (selectedPages.length === filePages.length) {
      setSelectedPages([]); // Deselect all
    } else {
      setSelectedPages(filePages.map((_, index) => index)); // Select all
    }
  };

  const handleImageClick = (image) => {
    setSelectedImage(image);
    setIsImageOpen(true);
  };
  
  const handleCloseImage = () => {
    setIsImageOpen(false);
    setSelectedImage(null);
  };

  // Handler for submitting the quiz

  const handleSubmit = async () => {
    const data = {
      file: selectedFileId,
      page_indices: selectedPages,
      question_type: 'SA',
      name: quizName,
    };
  
    try {
      const response = await fetch(ENDPOINTS.CREATE_QUESTIONSET, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('accessToken')}`,
        },
        body: JSON.stringify(data),
      });
  
      if (!response.ok) {
        const errorData = await response.json();
        if (errorData.detail === "A QuestionSet with this name already exists in the specified course.") {
          setErrorMessage('Ein Frageset mit diesem Namen existiert bereits im ausgewählten Kurs. Bitte wähle einen anderen Namen.');
        } else {
          console.error('Failed to create quiz:', errorData);
          alert('Failed to create quiz. Please try again.');
        }
        return;
      }
  
      const responseData = await response.json();
      console.log('Quiz Created:', responseData);
      setSuccessMessage('Dein Quiz wird nun erstellt. Das kann einige Momente dauern.');
  
      if (responseData && responseData.question_request && responseData.question_request.id) {
        console.log('Calling onQuizCreated with ID:', responseData.question_request.id);
        onQuizCreated(responseData.question_request.id); 
      }
  
      setTimeout(() => {
        setTimeout(() => {
          console.log('Navigating to course page:', courseId);
          navigate(`/course/${courseId}?tab=2`);
          onClose();
        }, 100);
        
        onClose();
      }, 2000); 
  
    } catch (error) {
      console.error('Error creating quiz:', error);
      alert('Network error. Please try again.');
    }
  };
  

  const handleCloseSnackbar = () => {
    setErrorMessage('');
    setSuccessMessage(''); 
  };


  // Helper functions for rendering

  const renderCheckbox = (file, isFileReady) => (
    <Checkbox
      checked={selectedFileId === file.id}
      onChange={() => handleFileClick(file)}
      disabled={!isFileReady}
      inputProps={{ 'aria-label': 'Select file' }}
      color="secondary"
    />
  );
  

// UI for the dialog
  
  return (
    <>
      <Dialog open={open} onClose={onClose} fullWidth={true} maxWidth="xl">
        {/* Page 1 */}
        {currentPage === 1 ? (
          <>
            <DialogContent sx={{ ml: 3, mr: 6 }}>
              <DialogTitle sx={{ pl: 0 }}>Frageseterstellung</DialogTitle>
              <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
                <Box sx={{ mb: 2 }}>
                  <DialogTitle sx={{ pl: 0 }}>Name</DialogTitle>
                  <DialogContentText sx={{ pl: 0 }}>
                    Bitte gib einen Namen für dein Frageset ein.
                  </DialogContentText>
                  <TextField
                    autoFocus
                    id="courseName"
                    label="Fragensetname"
                    type="text"
                    fullWidth
                    variant="standard"
                    value={quizName}
                    onChange={(e) => setQuizName(e.target.value)}
                    sx={{ mt: 2 }}
                  />
                </Box>
                <Box sx={{ flex: 1 }}>
                  <DialogTitle sx={{ pl: 0 }}>Datei</DialogTitle>
                  <DialogContentText sx={{ pl: 0, mb: 2 }}>
                    Bitte wähle eine Datei aus, auf dessen Basis dein Frageset erstellt werden soll.
                  </DialogContentText>
                  <FileList
                        files={files}
                        filesError={filesError}
                        currentFileId={selectedFileId}
                        onFileClick={handleFileClick}
                        courseName={courseName}
                        fileControlComponent={(file) => renderCheckbox(file, isFileReady)} 
                        onFileUpdated={updateFileInState}
                        style={{ height: '400px', overflowY: 'auto' }}
                        processingMessage="Deine Datei ist noch nicht bereit für eine Quizerstellung. Bitte warte noch kurz."
                        isFileReady={isFileReady}
                    />
                </Box>
              </Box>
            </DialogContent>
            <DialogActions sx={{ justifyContent: 'space-between', p: 2 }}>
              <Button onClick={onClose} color="primary">
                Abbrechen
              </Button>
              <Button onClick={handleNextPage} color="primary" disabled={!quizName || !selectedFileId || !isFileReady}>
              Weiter
              </Button>
            </DialogActions>
          </>
        ) : (
          <>
            {/* Page 2 */}
            <DialogContent sx={{ ml: 3, mr: 6 }}>
              <DialogTitle sx={{ pl: 0 }}>Frageseterstellung</DialogTitle>
              <Box sx={{ display: 'flex', height: '100%' }}>
                <Box sx={{ width: '20%', padding: 2, display: "flex", flexDirection: "column", justifyContent: "center"}}>
                  <Typography variant="h6" sx={{ mb: 2 }}>Name</Typography>
                  <Typography color="grey" sx={{ mb: 2 }}>{quizName}</Typography>
                  <Typography sx={{ mb: 2 }} variant="h6">Datei</Typography>
                  <Typography color="grey" sx={{ wordBreak: 'break-word' }}>
                    {selectedFileId ? files.find(file => file.id === selectedFileId)?.file_name : ''}
                  </Typography>
                </Box>
                <Divider orientation="vertical" flexItem sx={{ height: 'auto', alignSelf: 'center' }} />
                <Box sx={{ flex: 1, padding: 2 }}>
                  <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
                    <Typography variant="h6">Seiten auswählen</Typography>
                    <Button onClick={handleSelectAll} color="primary" variant="outlined">Alle auswählen</Button>
                  </Box>
                  <DialogContentText sx={{ pl: 0, mb: 2 }}>
                    Bitte wähle alle Seiten deiner Datei aus, die für die Quizerstellung berücksichtigt werden sollen.
                  </DialogContentText>
                  <Paper elevation={4} sx={{ p: 2, height: '545px', overflowY: 'auto' }}>
                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 2 }}>
                      {filePages.map((page, index) => (
                        <Box key={index} sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '18%' }}>
                          <Box
                            sx={{
                              width: '100%',
                              border: selectedPages.includes(index) ? '2px solid black' : '1px solid grey',
                              borderRadius: '4px',
                              backgroundColor: selectedPages.includes(index) ? 'grey.300' : 'inherit',
                              p: 1,
                              cursor: 'pointer'
                            }}
                            onClick={(e) => handlePageSelection(index, e)} 
                          >
                            <Box
                              component="img"
                              src={`${BASE_URL}${page.image_url}`}
                              alt={`Page ${page.page_number}`}
                              sx={{
                                width: '100%',
                                borderRadius: '4px',
                              }}
                            />
                          </Box>
                          <Button
                            size="small"
                            sx={{
                              mt: 1,
                              display: 'block',
                              mx: 'auto',
                              textTransform: 'none',
                              fontSize: '0.8rem',
                              width: '80%',
                              color: 'grey.700',
                              '&:hover': {
                                backgroundColor: 'rgba(0, 0, 0, 0.1)', 
                              }
                            }}
                            onClick={(e) => {
                              e.stopPropagation(); 
                              handleImageClick(`${BASE_URL}${page.image_url}`);
                            }}
                          >
                            Details
                          </Button>
                        </Box>
                      ))}
                    </Box>
                  </Paper>
                </Box>
              </Box>
            </DialogContent>
            <DialogActions sx={{ justifyContent: 'space-between', p: 2 }}>
              <Button onClick={handlePreviousPage} color="primary">
                Zurück
              </Button>
              <Button onClick={handleSubmit} color="secondary" variant="contained" disabled={selectedPages.length === 0}>
                Set Erstellen
              </Button>
            </DialogActions>
          </>
        )}
        <Dialog open={isImageOpen} onClose={handleCloseImage} maxWidth="lg">
        <IconButton onClick={handleCloseImage} sx={{
                    marginLeft: 'auto',
                    width: 30,
                    height: 30,
                    padding: 0,
                    marginTop: '20px', marginRight:"20px"
                }}>
                    <CloseIcon />
                </IconButton>
          <DialogContent>
            {selectedImage && (
              <Box
                component="img"
                src={selectedImage}
                alt="Selected Page"
                sx={{
                  width: '100%',
                  borderRadius: '4px'
                }}
              />
            )}
          </DialogContent>
          <DialogActions>
          
          </DialogActions>
        </Dialog>
      </Dialog>
      {/* Snackbar for error and success messages */}
      <Snackbar open={!!errorMessage} autoHideDuration={6000} onClose={handleCloseSnackbar}>
        <Alert onClose={handleCloseSnackbar} severity="error" sx={{
      width: '100%',
      '& .MuiAlert-action': { // Targeting the action area specifically
        '.MuiIconButton-root': {
          marginLeft: 'auto',
          width: '30px', 
          height: '30px', 
          padding: '0px', 
          color: 'inherit', 
          '&:hover': {
            backgroundColor: 'rgba(0,0,0,0.1)', 
          }
        }
      }
    }} >
          {errorMessage}
        </Alert>
      </Snackbar>
      <Snackbar open={!!successMessage} autoHideDuration={6000} onClose={handleCloseSnackbar}>
  <Alert
    onClose={handleCloseSnackbar}
    severity="success"
    sx={{
      width: '100%',
      '& .MuiAlert-action': { 
        '.MuiIconButton-root': { 
          marginLeft: 'auto',
          width: '30px', 
          height: '30px', 
          padding: '0px', 
          color: 'inherit', 
          '&:hover': {
            backgroundColor: 'rgba(0,0,0,0.1)', 
          }
        }
      }
    }}
  >
    {successMessage}
  </Alert>
</Snackbar>


    </>
  );
};

export default CreateQuizDialog;
