import React from 'react';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import EditQuestionSetDialog from '../../features/EditQuestionSetDialog/EditQuestionSetDialog'; // Adjust the import path

export default function EditQuestionSetButton({ questionSetId, onQuestionSetUpdated }) {
    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <>
            <IconButton
                aria-label="edit"
                onClick={handleClickOpen}
                sx={{
                    width: 30,
                    height: 30,
                    padding: 0,
                    color: 'grey', // Default color
                    '&:hover': {
                        color: 'black', // Color on hover
                        backgroundColor: 'transparent' // Optional: makes background transparent on hover
                    }
                }}
            >
                <EditIcon style={{ fontSize: '20px' }} />
            </IconButton>
            {open && <EditQuestionSetDialog open={open} onClose={handleClose} questionSetId={questionSetId} onQuestionSetUpdated={onQuestionSetUpdated} />}
        </>
    );
}

