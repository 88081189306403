import React from 'react';
import RegistrationForm from '../../features/RegistrationForm/RegistrationForm';
import Button from '../../components/Button/Button';
import { useNavigate } from 'react-router-dom'; 
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';


const RegistrationPage = () => {
  const navigate = useNavigate();

  const handleBack = () => {
    navigate('/');
  };

  return (
    <Container component="main" maxWidth="sm" sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', height: '100vh', justifyContent: 'center' }}>
      <Typography variant="h4" component="h1" gutterBottom>
        Hier kannst du ein Konto erstellen
      </Typography>
      <Box sx={{
          width: 550, // Adjust as necessary
          background: '#FFF',
          boxShadow: '0px 4px 17px rgba(0, 0, 0, 0.3)',
          borderRadius: '30px',
          p: 4, // padding inside the box
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}>
        <RegistrationForm />
      </Box>
      <Button variant="outlined" sx={{ mt: 2 }} onClick={handleBack}>
        Zurück zur Startseite
      </Button>
    </Container>
  );
};



export default RegistrationPage;
