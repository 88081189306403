import React, { useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import ENDPOINTS  from '../../utils/apiConfig';

export default function CreateCourseDialog({ open, onClose, onCourseCreated }) {
    const [error, setError] = useState('');  // State to store error messages

    const handleSubmit = async (event) => {
        event.preventDefault();
        const formData = new FormData(event.currentTarget);
        const courseData = {
            name: formData.get('courseName')
        };

        try {
            const response = await fetch(ENDPOINTS.COURSES, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('accessToken')}`
                },
                body: JSON.stringify(courseData)
            });

            if (!response.ok) {
                const errorData = await response.json();
                console.error('Failed to create course:', errorData);
                // Handle specific known errors
                if (errorData.name && errorData.name.name === "A course with this name already exists for this user.") {
                    setError('Ein Kurs mit diesem Namen existiert bereits für diesen Benutzer. Bitte wähle einen anderen Namen.');
                } else if (errorData.name && errorData.name[0] === "This field may not be blank.") {
                    setError('Das Feld "Kursname" darf nicht leer sein.');
                } else {
                    setError('Ein Fehler ist aufgetreten. Bitte versuche es erneut.');
                }
                return; // Stop further execution in case of error
            }

            const responseData = await response.json();
            console.log('Course Created:', responseData);
            onClose();  // Close dialog after successful API response
            onCourseCreated();  // Trigger course list refresh on MainPage
            setError('');  // Reset error state
        } catch (error) {
            console.error('Error creating course:', error);
            setError('Netzwerkfehler. Bitte versuchen Sie es erneut.'); // Handle unexpected errors
        }
    };

    return (
        <Dialog open={open} onClose={() => { setError(''); onClose(); }}>
            <DialogTitle>Neuen Kurs erstellen</DialogTitle>
            <form onSubmit={handleSubmit}>
                <DialogContent>
                    <TextField
                        autoFocus
                        margin="dense"
                        id="courseName"
                        label="Kursname"
                        type="text"
                        fullWidth
                        variant="standard"
                        name="courseName"
                        error={!!error}
                        helperText={error}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => { setError(''); onClose(); }}>Abbrechen</Button>
                    <Button type="submit">Kurs erstellen</Button>
                </DialogActions>
            </form>
        </Dialog>
    );
}
