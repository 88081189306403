import React, { useState } from 'react';
import Button from '@mui/material/Button';
import LearningMode from '../../features/LearningMode/LearningMode';

const StartLearningModeButton = ({ sx, questionSetId, onOpenChange }) => {
  const [open, setOpen] = useState(localStorage.getItem(`learningModeOpen-${questionSetId}`) === 'true');

  const handleClickOpen = () => {
    setOpen(true);
    localStorage.setItem(`learningModeOpen-${questionSetId}`, 'true');
    if (onOpenChange) {
      onOpenChange(true);
    }
  };

  const handleClose = () => {
    setOpen(false);
    localStorage.removeItem(`learningModeOpen-${questionSetId}`);
    if (onOpenChange) {
      onOpenChange(false);
    }
  };
  

  return (
    <>
      <Button
        variant='contained'
        color='secondary'
        size='large'
        onClick={handleClickOpen}
        sx={{ height: '75px', ...sx }} // Merge passed sx with default height
      >
        Lernmodus starten
      </Button>
      <LearningMode open={open} onClose={handleClose} questionSetId={questionSetId} />
    </>
  );
};

export default StartLearningModeButton;
