// services/EditQuestionService.js
import ENDPOINTS from '../utils/apiConfig';
import { refreshToken } from '../utils/AuthenticationService'; // Ensure this is where you handle token fetching

export const editQuestion = async (questionId, updatedData) => {
  let accessToken = localStorage.getItem('accessToken');
  const url = ENDPOINTS.EDIT_QUESTION(questionId);

  try {
    let response = await fetch(url, {
      method: 'PATCH',
      headers: {
        'Authorization': `Bearer ${accessToken}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(updatedData),
    });

    if (!response.ok) {
      if (response.status === 401) {
        accessToken = await refreshToken();
        response = await fetch(url, {
          method: 'PATCH',
          headers: {
            'Authorization': `Bearer ${accessToken}`,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(updatedData),
        });
      }
      if (!response.ok) {
        const errorData = await response.json();
        console.error('API Error:', errorData);
        throw new Error(errorData.detail || 'Unknown API error');
      }
    }

    return await response.json();
  } catch (error) {
    console.error('Error editing question:', error);
    throw error;
  }
};
