import React, { useState } from 'react';
import { Typography, Box, Select, MenuItem, Input } from '@mui/material';

const EditableField = ({ label, value, editable, onSave }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [localValue, setLocalValue] = useState(value);

  const handleEdit = () => {
    if (editable) {
      setIsEditing(true);
    }
  };

  const handleSave = () => {
    onSave(localValue);
    setIsEditing(false);
  };

  const handleChange = (event) => {
    setLocalValue(event.target.value);
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      handleSave();
    } else if (event.key === 'Escape') {
      setIsEditing(false);
      setLocalValue(value); // Reset on escape
    }
  };

  const genderOptions = [
    { value: 'F', label: 'Weiblich' },
    { value: 'M', label: 'Männlich' },
    { value: 'D', label: 'Divers' },
    { value: 'O', label: 'Keine Angabe' },
  ];

  const getGenderLabel = (value) => {
    const option = genderOptions.find(option => option.value === value);
    return option ? option.label : 'N/A';
  };

  return (
    <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
      <Typography 
        sx={{ width: '150px', fontWeight: 'bold', cursor: editable ? 'pointer' : 'default' }}
        onClick={handleEdit} // Only Typography which acts as label is clickable
      >
        {label}:
      </Typography>
      {isEditing ? (
        label === 'Geschlecht' ? (
          <Select
            size="small"
            value={localValue}
            onChange={handleChange}
            onBlur={handleSave}
            onKeyDown={handleKeyDown}
            autoFocus
            displayEmpty
          >
            {genderOptions.map(option => (
              <MenuItem key={option.value} value={option.value}>{option.label}</MenuItem>
            ))}
          </Select>
        ) : (
          <Input
            size="small"
            type={label === 'Geburtstag' ? 'date' : 'text'}
            value={localValue}
            onChange={handleChange}
            onBlur={handleSave}
            onKeyDown={handleKeyDown}
            autoFocus
            disableUnderline
            sx={{ width: 'fit-content', flexGrow: 1 }}
          />
        )
      ) : (
        <Typography sx={{ ml: 2, flexGrow: 1, cursor: editable ? 'pointer' : 'default' }} onClick={handleEdit}>
          {label === 'Geschlecht' ? getGenderLabel(value) : 
           label === 'Geburtstag' ? (value ? new Date(value).toLocaleDateString() : 'N/A') : 
           (value || 'N/A')}
        </Typography>
      )}
    </Box>
  );
};

export default EditableField;


