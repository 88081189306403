import React from 'react';
import { useNavigate } from 'react-router-dom';
import Button from '../../components/Button/Button'; // Ensure this path is correct
import './TosPage.css';

const TosPage = () => {
    const navigate = useNavigate();
  
    const navigateToRegister = () => {
      navigate('/register'); // Navigate to the Registration Page
    };
  
    const navigateToLogin = () => {
      navigate('/login'); // Navigate to the Login Page
    };
  
    return (
      <div className="landing-page">
        <h1>Hier stehen die AGBS</h1>
        <p>
          
        </p>
        
        <Button onClick={navigateToRegister}>Registrieren</Button>
        
        <Button onClick={navigateToLogin}>Anmelden</Button>
      </div>
    );
  };
  
  export default TosPage;
  