import ENDPOINTS from '../utils/apiConfig'; // Adjust the import path according to your project structure
import { refreshToken } from '../utils/AuthenticationService'; // Import the refreshToken function

export const fetchUserCourses = async () => {
    let accessToken = localStorage.getItem('accessToken');
    try {
        let response = await fetch(ENDPOINTS.COURSES, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${accessToken}`,
                'Content-Type': 'application/json'
            }
        });

        if (!response.ok) {
            if (response.status === 401) {
                // Access token might have expired, try refreshing it
                accessToken = await refreshToken();
                response = await fetch(ENDPOINTS.COURSES, {
                    method: 'GET',
                    headers: {
                        'Authorization': `Bearer ${accessToken}`,
                        'Content-Type': 'application/json'
                    }
                });
            }
            if (!response.ok) {
                throw new Error('Failed to fetch courses');
            }
        }

        return await response.json();
    } catch (error) {
        console.error('Error fetching courses:', error);
        throw error; // Re-throw to handle it in the component
    }
};
