import React, { useState, useEffect } from 'react';
import { Routes, Route } from 'react-router-dom';
import ProtectedRoute from '../utils/ProtectedRoute';
import './App.css';
import LandingPage from '../pages/LandingPage/LandingPage';
import RegistrationPage from '../pages/RegistrationPage/RegistrationPage';
import LoginPage from '../pages/LoginPage/LoginPage';
import TosPage from '../pages/TosPage/TosPage';
import MainPage from '../pages/MainPage/MainPage';
import ResetPasswordPage from '../pages/ResetPasswordPage/ResetPasswordPage';
import ConfirmResetPasswordPage from '../pages/ConfirmResetPasswordPage/ConfirmResetPasswordPage';
import CoursePage from '../pages/CoursePage/CoursePage';
import MainLayout from '../layouts/MainLayout';
import ConfirmEmailPage from '../pages/ConfirmEmailPage/ConfirmEmailPage';
import { AuthProvider } from '../contexts/AuthContext';
import { fetchUserDetails } from '../services/UserService';
import NotFound from '../pages/NotFound';
import AccountActivatedPage from '../pages/AccountActivatedPage/AccountActivatedPage';
import ActivationErrorPage from '../pages/ActivationErrorPage/ActivationErrorPage';

function App() {
  const [open, setOpen] = useState(true);
  const [userName, setUserName] = useState('');

  useEffect(() => {
    const loadUserDetails = async () => {
      try {
        const userDetails = await fetchUserDetails();
        setUserName(userDetails.username);
      } catch (error) {
        console.error('Error fetching user details:', error);
      }
    };
    loadUserDetails();
  }, []);

  const toggleDrawer = () => {
    setOpen(!open);
  };

  return (
    <div className="App">
      <AuthProvider>
        <Routes>
          <Route path="/" element={<LandingPage />} />
          <Route path="/register" element={<RegistrationPage />} />
          <Route path="/login" element={<LoginPage />} />
          <Route path="/tos" element={<TosPage />} />
          <Route path="/reset-password" element={<ResetPasswordPage />} />
          <Route path="/confirm-reset-password" element={<ConfirmResetPasswordPage />} />
          <Route path="/confirm-email" element={<ConfirmEmailPage />} />
          <Route path="/account-activated" element={<AccountActivatedPage />} />
          <Route path="/error" element={<ActivationErrorPage />} />
            <Route path="main" element={<ProtectedRoute><MainPage /></ProtectedRoute>} />
            <Route path="course/:courseId" element={<ProtectedRoute><CoursePage /></ProtectedRoute>} />
            <Route path="*" element={<NotFound />} /> 
       
        </Routes>
      </AuthProvider>
    </div>
  );
}

export default App;



