import React, { createContext, useContext, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { refreshToken as fetchRefreshToken } from '../utils/AuthenticationService';

const AuthContext = createContext();

export function useAuth() {
  return useContext(AuthContext);
}

export const AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(() => !!localStorage.getItem('access_token'));
  const [loading, setLoading] = useState(true);
  const [logoutMessage, setLogoutMessage] = useState(false);
  const navigate = useNavigate();

  const login = (tokens) => {
    localStorage.setItem('access_token', tokens.access);
    localStorage.setItem('refresh_token', tokens.refresh);
    setIsAuthenticated(true);
    setLogoutMessage(false);
  };

  const logout = () => {
    localStorage.removeItem('access_token');
    localStorage.removeItem('refresh_token');
    setIsAuthenticated(false);
    setLogoutMessage(true);
  };

  const checkAuthStatus = async () => {
    const accessToken = localStorage.getItem('access_token');
    const refreshToken = localStorage.getItem('refresh_token');

    if (accessToken) {
      setIsAuthenticated(true);
    } else if (refreshToken) {
      try {
        const newAccessToken = await fetchRefreshToken();
        localStorage.setItem('access_token', newAccessToken);
        setIsAuthenticated(true);
      } catch (error) {
        logout();
      }
    } else {
      logout();
    }

    setLoading(false);
  };

  useEffect(() => {
    checkAuthStatus();
  }, []);

  return (
    <AuthContext.Provider value={{ isAuthenticated, login, logout, loading, logoutMessage }}>
      {children}
    </AuthContext.Provider>
  );
};
