import React from 'react';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import DeleteCourseDialog from '../../features/DeleteCourseDialog/DeleteCourseDialog';

export default function EditCourseButton({ courseId, onCourseUpdated }) {
    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <>
            <IconButton
                aria-label="edit"
                onClick={handleClickOpen}
                sx={{
                    width: 30, 
                    height: 30,
                padding: 0,
                    color: 'grey', // Default color
                    '&:hover': {
                        color: 'black', // Color on hover
                        backgroundColor: 'transparent' // Optional: makes background transparent on hover
                    }
                }}
            >
                <DeleteIcon style={{ fontSize: '20px' }} />
            </IconButton>
            {open && <DeleteCourseDialog open={open} onClose={handleClose} courseId={courseId} onCourseUpdated={onCourseUpdated} />}
        </>
    );
}
