import React, { useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import Snackbar from '@mui/material/Snackbar';

const ProtectedRoute = ({ children }) => {
  const { isAuthenticated, loading, logoutMessage } = useAuth();
  const [open, setOpen] = React.useState(false);
  const [shouldRedirect, setShouldRedirect] = React.useState(false);

  useEffect(() => {
    if (!loading && !isAuthenticated && logoutMessage) {
      setOpen(true);
      setTimeout(() => {
        setOpen(false);
        setShouldRedirect(true);
      }, 3000);
    }
  }, [isAuthenticated, loading, logoutMessage]);

  if (loading) {
    return <div>Loading...</div>; // Or a loading spinner
  }

  if (!isAuthenticated && shouldRedirect) {
    return <Navigate to="/login" replace />;
  }

  return (
    <>
      <Snackbar
        open={open}
        autoHideDuration={3000}
        onClose={() => {
          setOpen(false);
          setShouldRedirect(true);
        }}
        message="Du bist nicht angemeldet, bitte melde dich an, um Thinki zu nutzen."
      />
      {isAuthenticated ? children : null}
    </>
  );
};

export default ProtectedRoute;
