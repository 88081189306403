import React from 'react';
import { useState } from "react";
import { useAuth } from '../../contexts/AuthContext'; 
import "./LoginForm.css";
import { useNavigate } from 'react-router-dom';
import ENDPOINTS from '../../utils/apiConfig';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { createTheme, ThemeProvider, CircularProgress, Alert, Snackbar } from '@mui/material';





const theme = createTheme();

const LoginForm = () => {
  const navigate = useNavigate();
  const { login } = useAuth();
  const [loginInput, setLoginInput] = useState({
    value: "",
    isTouched: false,
  });
  const [password, setPassword] = useState({
    value: "",
    isTouched: false,
  });
  const [generalError, setGeneralError] = useState("");

  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault(); 
  };

  const getIsFormValid = () => {
    return loginInput.value.trim().length > 0 && password.value.length >= 1;
  };

  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [loading, setLoading] = useState(false);


  const handleSubmit = async (e) => {
    e.preventDefault();
    setGeneralError("");
    setLoading(true);
    if (getIsFormValid()) {
      const loginData = {
        login: loginInput.value,
        password: password.value,
      };

      try {
        const response = await fetch(ENDPOINTS.LOGIN, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(loginData),
        });

        const data = await response.json();
        setLoading(false);
        if (response.ok) {
          localStorage.setItem('accessToken', data.access);
          localStorage.setItem('refreshToken', data.refresh);
          setOpenSnackbar(true); // Show success message
          setTimeout(() => {
            navigate('/main');
          }, 2000);
          login(data); 
        } else {
          if (data.error && data.error.includes("Email not verified")) {
            setGeneralError("Bitte bestätige zunächst deine Email Adresse.");
        } else {
          setGeneralError("Entweder deine Login Daten oder dein Passwort ist falsch. Bitte überprüfe deine Eingaben.");
        }
      }
      } catch (error) {
        setLoading(false);
        console.error("Network error:", error);
        setGeneralError("Ein Netzwerkfehler ist aufgetreten. Bitte versuche es später erneut.");
      }
    } else {
      setLoading(false)
      setGeneralError("Bitte überprüfen deine Eingaben.");
    }
  };

  return (
    <ThemeProvider theme={createTheme()}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 2,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Typography component="h1" variant="h5">
            Anmeldung
          </Typography>
          <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 1 }}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="loginInput"
              label="Email oder Nutzername"
              name="loginInput"
              autoComplete="loginInput"
              autoFocus
              value={loginInput.value}
              onChange={(e) => setLoginInput({ ...loginInput, value: e.target.value })}
              onBlur={() => setLoginInput({ ...loginInput, isTouched: true })}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="Passwort"
              type={showPassword ? 'text' : 'password'}
              id="password"
              autoComplete="current-password"
              value={password.value}
              onChange={(e) => setPassword({ ...password, value: e.target.value })}
              onBlur={() => setPassword({ ...password, isTouched: true })}
              InputProps={{ // This prop applies the end adornment for the text field
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                      sx={{
                        width: 15, 
                        height: 15, 
                        padding: 0, 
                        marginRight: .5, 
                      }}
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                      
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            {generalError && (
              <Typography color="error" sx={{ mt: 2 }}>
                {generalError}
              </Typography>
            )}
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
              disabled={!getIsFormValid()}
            >
              Anmelden
            </Button>
            <Grid container direction="column" spacing={1}> {/* Adjust the spacing as needed */}
              <Grid item xs>
                <Link href="reset-password" variant="body2">
                  Passwort vergessen?
                </Link>
              </Grid>
              <Grid item xs>
                <Link href="/register" variant="body2">
                  Noch kein Konto? Hier registrieren
                </Link>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Container>
      <>
        {loading && <CircularProgress />}
        <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={() => setOpenSnackbar(false)}>
          <Alert onClose={() => setOpenSnackbar(false)} severity="success" sx={{
      width: '100%',
      '& .MuiAlert-action': { // Targeting the action area specifically
        '.MuiIconButton-root': { // Targeting the IconButton itself
          marginLeft: 'auto',
          width: '30px', // Set width of the IconButton
          height: '30px', // Set height of the IconButton
          padding: '0px', // Remove padding around the icon
          color: 'inherit', // Use inherited color (optional)
          '&:hover': {
            backgroundColor: 'rgba(0,0,0,0.1)', // Custom hover background color
          }
        }
      }
    }}>
            Anmeldung erfolgreich! Du wirst weitergeleitet...
          </Alert>
        </Snackbar>
        
</>

    </ThemeProvider>
  );
};

export default LoginForm;

