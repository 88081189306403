import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Snackbar from '@mui/material/Snackbar';
import Button from '@mui/material/Button';

const NotFound = () => {
  const navigate = useNavigate();
  const [open, setOpen] = React.useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      navigate('/'); // Redirect to the homepage or login page after 3 seconds
    }, 3000);
    return () => clearTimeout(timer);
  }, [navigate]);

  const handleClose = () => {
    setOpen(false);
    navigate('/'); // Redirect immediately if the snackbar is closed
  };

  return (
    <>
      <h1>404 - Diese Seite existiert nicht.</h1>
      <Snackbar
        open={open}
        onClose={handleClose}
        autoHideDuration={3000}
        message="Du wirst zum Login weitergeleitet..."
        action={
          <Button color="secondary" size="small" onClick={handleClose}>
            Schließen
          </Button>
        }
      />
    </>
  );
};

export default NotFound;
