import * as React from 'react';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import DashboardIcon from '@mui/icons-material/Dashboard';
import PeopleIcon from '@mui/icons-material/People';
import FolderIcon from '@mui/icons-material/Folder';
import { Typography } from '@mui/material';
import { fetchUserCourses } from '../services/CourseService';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom'; 
import { UserMenu } from './Menus/UserMenu';



export const MainListItems = () => {
  const navigate = useNavigate();  // Create a navigation function

    const handleMainClick = () => {
        navigate('/main');  // Assuming the main page is the root
    };

    return (
        <ListItemButton onClick={handleMainClick}>
            <ListItemIcon>
                <DashboardIcon />
            </ListItemIcon>
            <ListItemText primary="Meine Kurse" />
        </ListItemButton>
    );
};

export const secondaryListItems = (
    <React.Fragment>
        <UserMenu />
    </React.Fragment>
  );

export const CourseListItems = ({ onCourseCreated }) => {
  const [courses, setCourses] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
      fetchCourses();
  }, [onCourseCreated]); // Dependency on onCourseCreated to trigger re-fetch

  const fetchCourses = async () => {
      try {
          const fetchedCourses = await fetchUserCourses();
          setCourses(fetchedCourses);
      } catch (error) {
          console.error('Failed to fetch courses:', error);
      }
  };

  const handleCourseClick = (courseId, tab = '1') => {
      navigate(`/course/${courseId}`,{ state: { tab } });
  }

  return (
      <React.Fragment>
          {courses.map((course) => (
              <ListItemButton key={course.id} onClick={() => handleCourseClick(course.id)}>
                <ListItemIcon sx={{ minWidth: 10, justifyContent: 'center', mr: 2}}>
                        <FolderIcon fontSize="large"/>
                        <Typography
                            component="span"
                            sx={{
                            position: 'absolute',
                            color: 'white',
                            fontWeight: 'bold',
                            fontSize: '0.7rem',
                            top: '50%', // Centers vertically, but we'll adjust this
                            transform: 'translateY(-35%)' // Adjust to center, or move slightly down
                          }}
                          >
                          {course.name.substring(0,2).toUpperCase()}
                        </Typography>
                    </ListItemIcon>
                  <ListItemText primary={course.name} sx={{ my: 0, overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}/>
              </ListItemButton>
          ))}
      </React.Fragment>
  );
};