import React, { useState, useEffect } from 'react';
import { Drawer, Typography, IconButton, Box, Divider } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import { Worker, Viewer } from '@react-pdf-viewer/core';
import '@react-pdf-viewer/core/lib/styles/index.css';
import ENDPOINTS from '../utils/apiConfig';

const FilePreviewDrawer = ({ open, onClose, file }) => {
    const [pdfUrl, setPdfUrl] = useState(null);

    useEffect(() => {
        if (file) {
            const fetchPDF = async () => {
                try {
                    const response = await fetch(ENDPOINTS.DOWNLOAD(file.id), {
                        method: 'GET',
                        headers: {
                            'Authorization': `Bearer ${localStorage.getItem('accessToken')}`,
                        },
                    });
                    if (response.ok) {
                        const blob = await response.blob();
                        const url = URL.createObjectURL(blob);
                        setPdfUrl(url);
                    } else {
                        throw new Error('Failed to fetch PDF');
                    }
                } catch (error) {
                    console.error('Error fetching PDF:', error);
                }
            };
            fetchPDF();
        }
    }, [file]); // Re-run when `file` changes

    return (
        <Drawer
            anchor="right"
            open={open}
            onClose={onClose}
            ModalProps={{
                keepMounted: true, // Better open performance on mobile.
                style: { zIndex: 1400 } // Ensure this is higher than AppBar's z-index
            }}
            sx={{
                width: 660,
                flexShrink: 0,
                '& .MuiDrawer-paper': {
                    width: 660,
                    boxSizing: 'border-box',
                    overflow: 'hidden',
                }
            }}
        >
            <Box sx={{
                position: 'sticky',
                top: 0,
                backgroundColor: 'white', // Ensures the background stays consistent
                zIndex: 1, // Ensures it stays above the content
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                padding: 2,
                marginTop: 1,
            }}>
                <InsertDriveFileIcon fontSize="large" sx={{ marginRight: 2 }} />
                <Typography variant="subtitle1" sx={{
                    flexGrow: 1,
                    overflow: 'hidden',
                    whiteSpace: 'nowrap',
                    textOverflow: 'ellipsis',
                    maxWidth: '90%', // Allocate 75% of the space to the filename
                }}>
                    {file ? file.file_name : 'No file selected'}
                </Typography>
                <IconButton onClick={onClose} sx={{
                    marginLeft: 'auto',
                    width: 30,
                    height: 30,
                    padding: 0,
                }}>
                    <CloseIcon />
                </IconButton>
            </Box>
            <Divider sx={{ my: 1 }} />
            {pdfUrl && (
                <Box sx={{
                    flexGrow: 1,
                    overflowY: 'auto', // Ensure the Box is scrollable
                    padding: 2,
                    height: 'calc(100% - 64px)', // Adjust height to account for the header (64px is an example height)
                }}>
                    <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.min.js">
                        <Viewer fileUrl={pdfUrl} />
                    </Worker>
                </Box>
            )}
        </Drawer>
    );
};

export default FilePreviewDrawer;


