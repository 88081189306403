import React from 'react';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';

export default function EditQuestionButton({ onClick }) {
    return (
        <IconButton
            aria-label="edit"
            onClick={onClick}
            sx={{
                width: 30, 
                height: 30,
                padding: 0,
                color: 'grey', // Default color
            }}
        >
            <EditIcon style={{ fontSize: '20px' }} />
        </IconButton>
    );
}
