import React from 'react';
import { useNavigate } from 'react-router-dom';
import Button from '../../components/Button/Button'; 
import ConfirmResetPasswordForm from '../../features/ConfirmResetPasswordForm/ConfirmResetPasswordForm';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';

const ConfirmResetPasswordPage = () => {
  const navigate = useNavigate();

  const handleBack = () => {
    navigate('/');
  };

  return (
    <Container component="main" maxWidth="sm" sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', height: '100vh', justifyContent: 'center' }}>
      <Typography variant="h4" component="h1" gutterBottom>
        Bitte lege ein neues Passwort fest
      </Typography>
      <Box sx={{
          width: 550, // Adjust as necessary
          background: '#FFF',
          boxShadow: '0px 4px 17px rgba(0, 0, 0, 0.3)',
          borderRadius: '30px',
          p: 2, // padding inside the box
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}>
        <ConfirmResetPasswordForm />
      </Box>
      <Button variant="outlined" sx={{ mt: 2 }} onClick={handleBack}>
        Zurück zur Startseite
      </Button>
    </Container>
  );
};

export default ConfirmResetPasswordPage;
