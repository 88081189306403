import React, { useState, useEffect } from 'react';
import { Box, Paper, Typography, CircularProgress } from '@mui/material';
import EditFileButton from '../Button/EditFileButton';
import DeleteFileButton from '../Button/DeleteFileButton';
import InsertDriveFile from '@mui/icons-material/InsertDriveFile';

const FileList = ({
  files,
  filesError,
  isLoading,
  currentFileId,
  onFileClick,
  onFileUpdated,
  courseName,
  fileControlComponent,
  style,
  processingMessage,
  isFileReady  
}) => {
  // State to manage the delayed display of the processing message
  const [showProcessingMessage, setShowProcessingMessage] = useState(false);

  // Effect to debounce the display of the processing message
  useEffect(() => {
    if (!isFileReady) {
      const timer = setTimeout(() => {
        // Only show the processing message if the file is still not ready after 300 milliseconds
        if (!isFileReady) {
          setShowProcessingMessage(true);
        }
      }, 600); // 300 ms delay before showing the processing message

      return () => clearTimeout(timer); // Clear the timeout if the component unmounts or if isFileReady changes
    } else {
      setShowProcessingMessage(false); // Immediately hide the message when the file becomes ready
    }
  }, [isFileReady]);

  return (
    <Paper elevation={4} sx={{ p: 3, display: 'flex', flexDirection: 'column', height: '600px', width: "100%", ...style}}>
      <Typography variant="h6" gutterBottom sx={{ textAlign: 'left', pl: 1, pb: 2, backgroundColor: 'background.paper', width: '100%' }}>
        {courseName} - Meine Dateien
      </Typography>
      <Box sx={{
        overflowY: 'auto',
        flexGrow: 1,
        '&::-webkit-scrollbar': {
          width: '0.5em'
        },
        '&::-webkit-scrollbar-track': {
          boxShadow: 'inset 0 0 6px rgba(0,0,0,0.3)',
          borderRadius: '4px',
        },
        '&::-webkit-scrollbar-thumb': {
          backgroundColor: 'darkgrey',
          borderRadius: '4px',
        }
      }}>
         {isLoading ? (
          <CircularProgress color="secondary" style={{ margin: 'auto' }} />
        ) : filesError ? (
          <Typography sx={{ mt: 2, textAlign: 'left', width: '100%', color: 'text.secondary', pl: 1, pb: 2 }}>
            Error: {filesError}
          </Typography>
        ) : files.length > 0 ? (
          files.map((file) => (
            <Box
              key={file.id}
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                gap: 2,
                p: 2,
                width: '100%',
                '&:hover': {
                  backgroundColor: 'grey.200',
                  cursor: 'pointer'
                }
              }}
              onClick={() => onFileClick(file)}
            >
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, maxWidth: 'calc(100% - 48px)', overflow: 'hidden' }}>
                {fileControlComponent ? fileControlComponent(file, isFileReady) : <InsertDriveFile fontSize="large" />}
                <span style={{
                  whiteSpace: 'nowrap',
                  textOverflow: 'ellipsis',
                  overflow: 'hidden',
                }}>
                  {file.file_name}
                </span>
                {currentFileId === file.id && showProcessingMessage && (
                  <Typography sx={{ ml: 2, color: 'grey.600' }}>
                    {processingMessage || "Deine Datei wird verarbeitet..."}
                  </Typography>
                )}
              </Box>
              <Box sx={{ display: 'flex', gap: 1 }} onClick={(e) => e.stopPropagation()}>
                <EditFileButton fileId={file.id} onFileUpdated={onFileUpdated} />
                <DeleteFileButton fileId={file.id} onFileUpdated={onFileUpdated} />
              </Box>
            </Box>
          ))
          
        ) : filesError ? (
          <Typography sx={{ mt: 2, textAlign: 'left', width: '100%', color: 'text.secondary', pl: 1, pb: 2 }}>
            Error: {filesError}
          </Typography>
        ) : (
          <Typography sx={{ mt: 2, textAlign: 'left', width: '100%', color: 'text.secondary', pl: 1, pb: 2 }}>
            Du hast noch keine Dateien hochgeladen. Bitte lade zunächst eine Datei hoch.
          </Typography>
        )}
      </Box>
    </Paper>
  );
}

export default FileList;
