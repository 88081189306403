import { useEffect, useState, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { fetchCourseData } from '../../utils/AuthenticationService';
import React from 'react';
import { useNavigate } from 'react-router-dom'; 
import { createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import UploadFilesField from '../../components/UploadFilesField';
import { fetchFilesByCourse } from '../../services/FileService';
import FilePreviewDrawer from '../../components/FilePreviewDrawer';
import TopAppBar from '../../components/Appbar';
import SideDrawer from '../../components/Drawer';
import { useSidebar } from '../../contexts/SidebarContext';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabPanel from '@mui/lab/TabPanel';
import TabList from '@mui/lab/TabList';
import CreateQuizButton from '../../components/Button/CreateQuizButton';
import { fetchQuestionSetsByCourse } from '../../services/QuestionService';
import QuestionSetDetail from '../../components/QuestionSetDetail';
import { useLocation } from 'react-router-dom';
import FileList from '../../components/Lists/FileList';
import QuizList from '../../components/Lists/QuizList';
import usePollFiles from '../../hooks/usePollFiles';
import usePollQuizzes from '../../hooks/usePollQuizzes';


const drawerWidth = 240;

const CoursePage = () => {
  const { isOpen, toggleDrawer } = useSidebar();
  const [courses, setCourses] = useState([]);
  const [files, setFiles] = useState([]);
  const [questionSets, setQuestionSets] = useState([]);
  const [courseDetails, setCourseDetails] = useState(null);
  const [courseError, setCourseError] = useState(null);
  const [filesError, setFilesError] = useState(null);
  const [questionsError, setQuestionsError] = useState(null);
  const navigate = useNavigate();
  const { courseId } = useParams();
  const location = useLocation();
  const [previewOpen, setPreviewOpen] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [selectedQuestionSet, setSelectedQuestionSet] = useState(null);
  const [isViewingQuestionSet, setIsViewingQuestionSet] = useState(false);
  const [tabValue, setTabValue] = useState('1');
  const theme = createTheme();
  const [currentFileId, setCurrentFileId] = useState(null);
  const [currentQuizId, setCurrentQuizId] = useState(null);
  const [isQuizReady, setIsQuizReady] = useState(false);
  const [isLoadingFiles, setIsLoadingFiles] = useState(true);
  const [lastFileUpload, setLastFileUpload] = useState(Date.now());
  const [showLoadingMessage, setShowLoadingMessage] = useState(false);

  useEffect(() => {
    if (!courseDetails && !courseError) {
      const timer = setTimeout(() => {
        setShowLoadingMessage(true);
      }, 500); // Adjust delay time as needed
  
      return () => clearTimeout(timer); // Clean up the timer
    } else {
      setShowLoadingMessage(false); // Immediately hide the message if the content is loaded or there is an error
    }
  }, [courseDetails, courseError]);
  

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const urlTabValue = params.get('tab');

    if (urlTabValue) {
      setTabValue(urlTabValue);
    }

    const savedQuestionSet = localStorage.getItem('selectedQuestionSet');
    const savedIsViewingQuestionSet = localStorage.getItem('isViewingQuestionSet');

    if (savedQuestionSet && savedIsViewingQuestionSet === 'true') {
      setSelectedQuestionSet(JSON.parse(savedQuestionSet));
      setIsViewingQuestionSet(true);
    }

    const fetchInitialData = async () => {
      setIsLoadingFiles(true);
      try {
        const [courseData, filesData, questionSetsData] = await Promise.all([
          fetchCourseData(courseId),
          fetchFilesByCourse(courseId),
          fetchQuestionSetsByCourse(courseId)
        ]);

        setCourseDetails(courseData);
        setFiles(filesData);
        setQuestionSets(questionSetsData);
        setIsLoadingFiles(false);
      } catch (error) {
        console.error('Failed to load initial data:', error);
        setCourseError('Failed to load course data');
        setIsLoadingFiles(false);
      }
    };

    fetchInitialData();
  }, [courseId, location.search]);
  
  


  const handleQuizCreated = useCallback((newQuizId) => {
    console.log("handleQuizCreated called with newQuizId:", newQuizId);
    handleQuestionSetsRefresh();
    setCurrentQuizId(newQuizId);  // Start polling the new quiz status
    setIsQuizReady(false);  // Assume it's not ready until confirmed
}, [courseId]);

const handleQuestionSetsRefresh = async () => {
  console.log("Refreshing question sets for courseId:", courseId);
    try {
        const updatedQuestionSets = await fetchQuestionSetsByCourse(courseId);
        console.log("Updated question sets:", updatedQuestionSets);
        setQuestionSets(updatedQuestionSets);
    } catch (error) {
        console.error('Error refreshing question sets:', error);
        setQuestionsError('Failed to refresh question sets');
    }
};


  const updateFileInState = (updatedFile) => {
    setFiles(prevFiles => prevFiles.map(file => {
        return file.id === updatedFile.id ? updatedFile : file;
    }));
};

const handleQuizUpdate = (updatedQuiz) => {
  setQuestionSets(prevQuestionSets => prevQuestionSets.map(qs => qs.id === updatedQuiz.id ? updatedQuiz : qs));
  setIsQuizReady(updatedQuiz.questions_status !== 'in_progress');
};

usePollFiles(currentFileId, updateFileInState, setFilesError, setCurrentFileId);

usePollQuizzes(currentQuizId, handleQuizUpdate, setQuestionsError, setCurrentQuizId);


if (courseError) {
  return <div>Error: {courseError}</div>;
} else if (!courseDetails) {
  // Here, you can also check showLoadingMessage before showing the loading indicator
  return showLoadingMessage ? <div>Details werden geladen...</div> : null;
}


  const handleFileClick = (file) => {
    setSelectedFile(file);
    setPreviewOpen(true);
  };

  const handleQuestionSetClick = (questionSet) => {
    setSelectedQuestionSet(questionSet);
    setIsViewingQuestionSet(true);
    localStorage.setItem('selectedQuestionSet', JSON.stringify(questionSet));
    localStorage.setItem('isViewingQuestionSet', 'true');
  };

  const handleBackToQuestionSets = () => {
    setIsViewingQuestionSet(false);
    localStorage.removeItem('selectedQuestionSet');
    localStorage.removeItem('isViewingQuestionSet');
  };

  const handleCourseCreated = async () => {
    const fetchedCourses = await fetchCourseData(courseId);
    setCourses(fetchedCourses);
  };

  const handleFileUploadSuccess = async (fileId) => {
    setCurrentFileId(fileId); // Set the current file ID for polling
    const updatedFiles = await fetchFilesByCourse(courseId);
    setFiles(updatedFiles);
    setLastFileUpload(Date.now()); // Update the timestamp when files are updated
  };
 
  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
    navigate(`/course/${courseId}?tab=${newValue}`); // Update URL when tab changes
    localStorage.setItem('coursePageTab', newValue);

    if (newValue === '2') {
      setIsViewingQuestionSet(false);
      setSelectedQuestionSet(null);
      localStorage.removeItem('selectedQuestionSet');
      localStorage.removeItem('isViewingQuestionSet');
    }
  };



  return (
    <ThemeProvider theme={createTheme()}>
      <CssBaseline />
      <FilePreviewDrawer open={previewOpen} onClose={() => setPreviewOpen(false)} file={selectedFile} />
      <TopAppBar open={isOpen} toggleDrawer={toggleDrawer} greeting={courseDetails.name} />
      <SideDrawer open={isOpen} toggleDrawer={toggleDrawer} onCourseCreated={handleCourseCreated} />
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: 2,
          width: isOpen ? `calc(100% - ${drawerWidth}px)` : `calc(100% - ${theme.spacing(9)})`,
          ml: isOpen ? `${drawerWidth}px` : `${theme.spacing(9)}`,
          transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
          }),
          backgroundColor: "white",
          display: 'flex',
          flexDirection: 'column',
          height: '100vh',
          justifyContent: 'space-between',
        }}
      >
        <Toolbar />
        <Container maxWidth="false" sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column', justifyContent: "space-between" }}>
          <TabContext value={tabValue}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <TabList onChange={handleTabChange} aria-label="course tabs" indicatorColor="secondary" textColor="secondary">
                <Tab label="Dateien" value="1" sx={{ minWidth: 150, width: 'auto' }} />
                <Tab label="Fragesets" value="2" sx={{ minWidth: 150, width: 'auto' }} />
              </TabList>
            </Box>
            <TabPanel value="1">
              <Box alignItems="center" display="flex" sx={{ flexGrow: 1, justifyContent: 'space-between' }}>
                <UploadFilesField courseId={courseId} onFileUploadSuccess={handleFileUploadSuccess} />
                <CreateQuizButton
                  variant="outlined"
                  color="secondary"
                  size="small"
                  style={{ marginLeft: 'auto' }}
                  onQuizCreated={handleQuizCreated}
                  lastFileUpload={lastFileUpload}
                  courseName={courseDetails.name}
                />
                

              </Box>
              <Grid container spacing={3} sx={{ mt: 'auto', mb: 2 }}>
                <Grid item xs={12}>
                <FileList
                files={files}
                filesError={filesError}
                currentFileId={currentFileId} 
                onFileClick={handleFileClick}
                onFileUpdated={handleFileUploadSuccess}
                courseName={courseDetails.name}
                isLoading={isLoadingFiles} 
                />
                </Grid>
              </Grid>
            </TabPanel>
            <TabPanel value="2" sx={{ display: 'flex', flexDirection: 'column', height: '100%', p: 0, m: 0 }}>
              {isViewingQuestionSet ? (
                <QuestionSetDetail
                  questionSet={selectedQuestionSet}
                  onBack={handleBackToQuestionSets}
                />
              ) : (
                <>
                  <Box display="flex" justifyContent="left" alignItems="center" sx={{ flexGrow: 1 }}>
                  
                    <CreateQuizButton
                      variant="contained"
                      color="secondary"
                      size="large"
                      style={{ marginRight: 'auto' }}
                      sx={{ height: '80px' }}
                      onQuizCreated={handleQuizCreated}
                     lastFileUpload={lastFileUpload}
                      courseName={courseDetails.name}
                    />
                  </Box>
                  <Grid container spacing={3} sx={{ mb: 2 }}>
                    <Grid item xs={12}>
                    <QuizList
                    questionSets={questionSets}
                    questionsError={questionsError}
                    onQuestionSetClick={handleQuestionSetClick}
                    onQuestionSetUpdated={handleQuestionSetsRefresh}
                    currentQuizId={currentQuizId} 
                    isQuizReady={isQuizReady}
                    courseName={courseDetails.name}
                        />
                    </Grid>
                  </Grid>
                </>
              )}
            </TabPanel>
          </TabContext>
        </Container>
      </Box>
    </ThemeProvider>
  );
}

export default CoursePage;
