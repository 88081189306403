import React from 'react';
import IconButton from '@mui/material/IconButton';
import ShuffleIcon from '@mui/icons-material/Shuffle';

const ShuffleButton = ({ isShuffled, toggleShuffle }) => {
  return (
    <IconButton onClick={() => {
      console.log("Shuffle button clicked");  // Log when button is clicked
      toggleShuffle();
    }}
    color="secondary"
    sx={{
        mx: 8,
        width: 56,
        height: 56,
        borderRadius: '50%',
        border: isShuffled ? '3px solid' : '1px solid',  // Thicker border when shuffled
        borderColor: isShuffled ? '#AD7FB9' : 'rgba(0, 0, 0, 0.23)',  // Different border color when shuffled
        backgroundColor: isShuffled ? '#E9D6FF' : 'white',  // Light purple background when shuffled
        '&:hover': {
          backgroundColor: isShuffled ? '#EADAF2' : '#f5f5f5',  // Slightly darker purple on hover when shuffled
          borderColor: isShuffled ? '#AD7FB9' : 'secondary.main',  // Maintain the border color on hover when shuffled
        }
      }}>
      <ShuffleIcon style={{ color: isShuffled ? '#65256A' : 'rgba(0, 0, 0, 0.3)' }} />
    </IconButton>
  );
};

export default ShuffleButton;
