import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ThemeProvider, createTheme, CssBaseline, Box, Container, Grid, Paper, Typography, CircularProgress } from '@mui/material';
import TopAppBar from '../../components/Appbar';
import SideDrawer from '../../components/Drawer';
import { fetchUserCourses } from "../../services/CourseService";
import { fetchUserDetails } from '../../services/UserService';
import CreateCourseButton from '../../components/Button/CreateCourseButton';
import FolderIcon from '@mui/icons-material/Folder';
import EditCourseButton from '../../components/Button/EditCourseButton';
import DeleteCourseButton from '../../components/Button/DeleteCourseButton';
import { useSidebar } from '../../contexts/SidebarContext';

const drawerWidth = 240;

export default function MainPage() {
  const { isOpen, toggleDrawer } = useSidebar();
  const [courses, setCourses] = useState([]);
  const [isLoading, setIsLoading] = useState(true); // New state to track loading
  const [userName, setUserName] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    const loadUserDetails = async () => {
      try {
        const userDetails = await fetchUserDetails();
        setUserName(userDetails.username);
      } catch (error) {
        console.error('Error fetching user details:', error);
      }
    };

    const loadCourses = async () => {
      try {
        const fetchedCourses = await fetchUserCourses();
        setCourses(fetchedCourses);
      } catch (error) {
        console.error('Error fetching courses:', error);
      }
      setIsLoading(false); // Set loading to false after fetching courses
    };

    loadUserDetails();
    loadCourses();
  }, []);

  const handleCourseClick = (courseId) => {
    navigate(`/course/${courseId}`);
  };

  const handleCourseCreated = async () => {
    setIsLoading(true); // Set loading to true while fetching
    const fetchedCourses = await fetchUserCourses();
    setCourses(fetchedCourses);
    setIsLoading(false); // Set loading to false after fetching
  };

  const theme = createTheme();

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <TopAppBar open={isOpen} toggleDrawer={toggleDrawer} greeting={`Hallo, ${userName}!`} />
      <SideDrawer open={isOpen} toggleDrawer={toggleDrawer} onCourseCreated={handleCourseCreated} />
      <Box component="main" sx={{
        flexGrow: 1,
        p: 3,
        width: isOpen ? `calc(100% - ${drawerWidth}px)` : `calc(100% - ${theme.spacing(9)})`,
        ml: isOpen ? `${drawerWidth}px` : `${theme.spacing(9)}`,
        transition: theme.transitions.create(['width', 'margin'], {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
        backgroundColor: "white",
        display: 'flex',
        flexDirection: 'column',
        height: '100vh',
        justifyContent: 'space-between',
      }}>
        <Container maxWidth="false" sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column', justifyContent: "space-between"}}>
          <Box alignItems="center" display="flex" sx={{ flexGrow: 1, paddingTop: 8}}>
            <CreateCourseButton onCourseCreated={handleCourseCreated} />
          </Box>
          <Grid container spacing={3} sx={{ mt: 'auto', mb: 2 }}>
            <Grid item xs={12}>
              <Paper elevation={4} sx={{ p: 3, display: 'flex', flexDirection: 'column', height: '600px', width: "100%"}}>
                <Typography variant="h6" gutterBottom sx={{ textAlign: 'left', pl: 1, pb: 2, backgroundColor: 'background.paper', width: '100%' }}>
                  Meine Kurse
                </Typography>
                {isLoading ? (
                  <CircularProgress color="secondary" style={{ margin: 'auto' }} />
                ) : courses.length > 0 ? (
                  courses.map((course) => (
                    <Box
                      key={course.id}
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        gap: 2,
                        p: 2,
                        width: '100%',
                        '&:hover': {
                          backgroundColor: 'grey.200',
                          cursor: 'pointer'
                        }
                      }}
                      onClick={() => handleCourseClick(course.id)}
                    >
                      <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, maxWidth: 'calc(100% - 48px)', overflow: 'hidden' }}>
                        <FolderIcon fontSize="large" />
                        <span style={{
                          whiteSpace: 'nowrap',
                          textOverflow: 'ellipsis',
                          overflow: 'hidden',
                        }}>
                          {course.name}
                        </span>
                      </Box>
                      <Box sx={{ display: 'flex', gap: 1 }} onClick={(e) => e.stopPropagation()}>
                        <EditCourseButton courseId={course.id} onCourseUpdated={handleCourseCreated} />
                        <DeleteCourseButton courseId={course.id} onCourseUpdated={handleCourseCreated} />
                      </Box>
                    </Box>
                  ))
                ) : (
                  <Typography sx={{ mt: 2, textAlign: 'left', width: '100%', color: 'text.secondary', pl: 1, pb: 2 }}>
                    Du hast noch keine Kurse erstellt. Beginne damit einen neuen Kurs zu erstellen.
                  </Typography>
                )}
              </Paper>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </ThemeProvider>
  );
}




/*const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.25),
  '&:hover': {
    backgroundColor: alpha(theme.palette.common.white, 0.5),
  },
  marginLeft: 0,
  width: '100%',
  color: "black",
  [theme.breakpoints.up('sm')]: {
    marginLeft: theme.spacing(1),
    width: 'auto',
  },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));
*/