import { useEffect } from 'react';
import { fetchFileDetails } from '../services/FileService';

const usePollFiles = (fileId, setFile, setFileError, setCurrentFileId) => {
  useEffect(() => {
      if (!fileId) return; // Only proceed if fileId is not null

      const pollFile = async () => {
          try {
              const fileData = await fetchFileDetails(fileId);
              console.log(`File status: text_status=${fileData.text_status}, images_status=${fileData.images_status}`);
              setFile(fileData); // Update the file data in state

              if (fileData.text_status === 'completed' && fileData.images_status === 'completed') {
                  clearInterval(intervalId); // Stop polling
                  setCurrentFileId(null); // Reset the current file ID
              }
          } catch (error) {
              console.error('Error polling file details:', error);
              setFileError('Error fetching file details');
          }
      };
    

      const intervalId = setInterval(pollFile, 5000); // Poll every 5 seconds

      return () => clearInterval(intervalId); // Cleanup on unmount
  }, [fileId, setFile, setFileError, setCurrentFileId]); // Include setCurrentFileId in the dependencies array


  return null;
};


export default usePollFiles;


