import React from 'react';
import { styled, useTheme } from '@mui/material/styles';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import MenuIcon from '@mui/icons-material/Menu';

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['margin', 'width'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
}));

const TopAppBar = ({ toggleDrawer, greeting }) => {
  const theme = useTheme();

  return (
    <AppBar position="fixed" theme={theme}> 
      <Toolbar sx={{ pr: '24px', backgroundColor: '#FFD830' }}>
        <IconButton
          edge="start"
          color="inherit"
          aria-label="open drawer"
          onClick={toggleDrawer}
          sx={{ width: 40, height: 40, padding: 0 }}>
          <MenuIcon />
        </IconButton>
        <Typography component="h1" variant="h6" color="#B115D8" noWrap sx={{ ml: '30px' }}>
          {greeting}
        </Typography>
        <div style={{ flexGrow: 1 }} />
      </Toolbar>
    </AppBar>
  );
};

export default TopAppBar;


