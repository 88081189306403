import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import ENDPOINTS from '../../utils/apiConfig';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import { createTheme, ThemeProvider, CircularProgress, Alert, Snackbar } from '@mui/material';
import { useParams } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { useEffect } from 'react';

const useQuery = () => {
  const { search } = useLocation();
  return new URLSearchParams(search);
};


const ConfirmResetPasswordForm = () => {
  
    const navigate = useNavigate();
    const query = useQuery();
    const uid = query.get('uid');
    const token = query.get('token');  
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [password, setPassword] = useState({
        value: "",
        isTouched: false,
    });
    const [confirmPassword, setConfirmPassword] = useState({
        value: "",
        isTouched: false,
    });
    const [generalError, setGeneralError] = useState("");
    const [passwordError, setPasswordError] = useState([]);
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [loading, setLoading] = useState(false);

    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    };

    const handleClickShowConfirmPassword = () => {
        setShowConfirmPassword(!showConfirmPassword);
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault(); // This prevents the focus behavior on click
    };

    const getFriendlyErrorMessage = (error) => {
      switch(error) {
        case "Invalid token or UID or User does not exist.":
          return "Ungültige Authentifizierung oder Benutzer existiert nicht.";
        case "This password reset link has already been used.":
          return "Dieser Link wurde bereits verwendet.";
        case "Invalid token or token expired.":
          return "Dieser Link ist ungültig oder abgelaufen. Bitte fordere erneut einen Link an.";
        case "Invalid token or UID.":
          return "Ungültige Aufthentifizierung.";
        default:
          return "Ein unbekannter Fehler ist aufgetreten. Bitte versuche es erneut.";
      }
    }
    

    const getIsFormValid = () => {
        return (
            password.value.length >= 8 &&
            confirmPassword.value === password.value
        );
    };

    const handleSubmit = async (e) => {
      e.preventDefault();
      if (!getIsFormValid()) {
          setGeneralError("Bitte stelle sicher, dass das Passwort mindestens 8 Zeichen lang ist und die Passwörter übereinstimmen.");
          return;
      }
    
      setLoading(true); // Set loading before the API call
      try {
          const response = await fetch(ENDPOINTS.CONFIRM, {
              method: 'POST',
              headers: { 'Content-Type': 'application/json' },
              body: JSON.stringify({
                  uid: uid,
                  token: token,
                  new_password: password.value
              }),
          });
    
          const data = await response.json(); // Parse JSON response
          setLoading(false); // Disable loading after getting the response
    
          if (response.ok) {
              setOpenSnackbar(true);
              setTimeout(() => navigate('/login'), 3000); // Redirect after showing the message
          } else {
              let errorMessage = getFriendlyErrorMessage(data.error);
              setGeneralError(errorMessage);
          }
      } catch (error) {
          setLoading(false);
          console.error("Network error:", error);
          setGeneralError("Ein Netzwerkfehler ist aufgetreten. Bitte versuche es später erneut.");
      }
    };
    

  

    return (
        <ThemeProvider theme={createTheme()}>
          <Container component="main" maxWidth="xs">
            <CssBaseline />
            <Box
              sx={{
                marginTop: 2,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              <Typography component="h1" variant="h5">
                Bitte lege ein neues Passwort fest.
              </Typography>
              <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 1 }}>
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  id="password"
                  label="Passwort"
                  name="password"
                  type={showPassword ? 'text' : 'password'}
                  autoComplete="new-password"
                  value={password.value}
                  onChange={(e) => setPassword({ ...password, value: e.target.value })}
                  onBlur={() => setPassword({ ...password, isTouched: true })}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                          sx={{
                            width: 15,
                            height: 15,
                            padding: 0,
                            marginRight: .5,
                          }}
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  id="confirmPassword"
                  label="Passwort bestätigen"
                  name="confirmPassword"
                  type={showConfirmPassword ? 'text' : 'password'}
                  autoComplete="new-password"
                  value={confirmPassword.value}
                  onChange={(e) => setConfirmPassword({ ...confirmPassword, value: e.target.value })}
                  onBlur={() => setConfirmPassword({ ...confirmPassword, isTouched: true })}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowConfirmPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                          sx={{
                            width: 15,
                            height: 15,
                            padding: 0,
                            marginRight: .5,
                          }}
                        >
                          {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  sx={{ mt: 3, mb: 2 }}
                  disabled={loading}
                >
                  Neues Passwort bestätigen
                </Button>
                <Grid container direction="column" spacing={1}>
                  <Grid item xs>
                  {generalError && (
                  <Alert severity="error" sx={{ width: '100%', mt: 2, mb:2}}>
                 {generalError}
                 </Alert>
                      )}
                    <Link href="/login" variant="body2">
                      Zurück zum Login
                    </Link>
                  </Grid>
                </Grid>
              </Box>
            </Box>
            {loading && <CircularProgress />}
            <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={() => setOpenSnackbar(false)}>
              <Alert onClose={() => setOpenSnackbar(false)} severity="success" sx={{
                  width: '100%',
                  '& .MuiAlert-action': { 
                  '.MuiIconButton-root': { 
                   marginLeft: 'auto',
                   width: '30px', 
                   height: '30px', 
                   padding: '0px', 
                   color: 'inherit', 
                   '&:hover': {
                   backgroundColor: 'rgba(0,0,0,0.1)', 
                   }
                 }
                 }
                }}>
                Dein Passwort wurde erfolgreich zurückgesetzt. Du wirst in Kürze zum Login weitergeleitet.
              </Alert>
            </Snackbar>
          </Container>
        </ThemeProvider>
      );
    };
    
    export default ConfirmResetPasswordForm;