import ENDPOINTS from '../utils/apiConfig';
import { refreshToken } from '../utils/AuthenticationService'; // Import the refreshToken function

export const fetchUserDetails = async () => {
    let accessToken = localStorage.getItem('accessToken');

    try {
        let response = await fetch(ENDPOINTS.USER, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${accessToken}`,
                'Content-Type': 'application/json'
            }
        });

        if (!response.ok) {
            if (response.status === 401) {
                // Access token might have expired, try refreshing it
                accessToken = await refreshToken();
                response = await fetch(ENDPOINTS.USER, {
                    method: 'GET',
                    headers: {
                        'Authorization': `Bearer ${accessToken}`,
                        'Content-Type': 'application/json'
                    }
                });
            }
            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.detail || 'Failed to fetch user details');
            }
        }

        return await response.json();
    } catch (error) {
        console.error('Error fetching user details:', error);
        throw error;
    }
};

export const updateUserDetails = async (data) => {
    let accessToken = localStorage.getItem('accessToken');

    try {
        let response = await fetch(ENDPOINTS.USER, {
            method: 'PATCH',
            headers: {
                'Authorization': `Bearer ${accessToken}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        });

        if (!response.ok) {
            if (response.status === 401) {
                accessToken = await refreshToken();
                response = await fetch(ENDPOINTS.USER, {
                    method: 'PATCH',
                    headers: {
                        'Authorization': `Bearer ${accessToken}`,
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(data)
                });
            }
            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.detail || 'Failed to update user details');
            }
        }

        return await response.json();
    } catch (error) {
        console.error('Error updating user details:', error);
        throw error;
    }
};
