import { useEffect } from 'react';
import { fetchQuestionSetById } from '../services/GetQuestionSetService';

const usePollQuizzes = (questionSetId, setQuiz, setQuizError, setCurrentQuizId) => {
  useEffect(() => {
    if (!questionSetId) return; // Only proceed if questionSetId is not null

    const pollQuiz = async () => {
      try {
        console.log ('Quiz Polling starts');
        const quizData = await fetchQuestionSetById(questionSetId);
        console.log(`Quiz status: questions_status=${quizData.questions_status}`);
        setQuiz(quizData); // Update the quiz data in state

        if (quizData.questions_status !== 'in_progress') {
          clearInterval(intervalId); // Stop polling if the status is not 'in_progress'
          setCurrentQuizId(null); // Optionally reset the current quiz ID
        }
      } catch (error) {
        console.error('Error polling quiz details:', error);
        setQuizError('Error fetching quiz details');
      }
    };

    const intervalId = setInterval(pollQuiz, 10000); // Poll every 10 seconds

    return () => clearInterval(intervalId); // Cleanup on unmount
  }, [questionSetId, setQuiz, setQuizError, setCurrentQuizId]);

  return null;
};

export default usePollQuizzes;
