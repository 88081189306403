import React, { useState } from 'react';
import Button from '@mui/material/Button';
import AnsweringMode from '../../features/AnsweringMode/AnsweringMode';

const StartAnsweringModeButton = ({ sx, questionSetId, onOpenChange }) => {
  const [open, setOpen] = useState(localStorage.getItem(`answeringModeOpen-${questionSetId}`) === 'true');

  const handleClickOpen = () => {
    setOpen(true);
    localStorage.setItem(`answeringModeOpen-${questionSetId}`, 'true');
    if (onOpenChange) {
      onOpenChange(true);
    }
  };

  const handleClose = () => {
    setOpen(false);
    localStorage.removeItem(`answeringModeOpen-${questionSetId}`);
    if (onOpenChange) {
      onOpenChange(false);
    }
  };

  return (
    <>
      <Button
        variant = 'contained'
        color = 'secondary'
        size = 'large'
        onClick={handleClickOpen}
        sx={{ height: '75px', ...sx }} // Merge passed sx with default height
      >
        Antwortmodus starten
      </Button>
      <AnsweringMode open={open} onClose={handleClose} questionSetId={questionSetId} />
    </>
  );
};

export default StartAnsweringModeButton;