import React, { useState } from 'react';
import Button from '@mui/material/Button';
import CreateCourseDialog from '../../features/CreateCourseDialog/CreateCourseDialog';
import CreateNewFolderIcon from '@mui/icons-material/CreateNewFolder';

export default function CreateCourseButton({ onCourseCreated }) {
    const [open, setOpen] = useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <div>
            <Button variant="contained" size="large" color="secondary" onClick={handleClickOpen} endIcon={<CreateNewFolderIcon/>}>
                Neuen Kurs erstellen
            </Button>
            <CreateCourseDialog open={open} onClose={handleClose} onCourseCreated={onCourseCreated}/>
        </div>
    );
}
