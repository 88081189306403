
import React from 'react';
import { styled } from '@mui/material/styles';
import MuiDrawer from '@mui/material/Drawer';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import Box from '@mui/material/Box';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { MainListItems, CourseListItems, secondaryListItems } from './ListItemsNavbar';
import ThinkiLogo from '../assets/ThinkiLogo.svg';

const drawerWidth = 240;

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(({ theme, open }) => ({
  '& .MuiDrawer-paper': {
    position: 'fixed',
    whiteSpace: 'nowrap',
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    boxSizing: 'border-box',
    ...(!open && {
      overflowX: 'hidden',
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      width: theme.spacing(9),
      [theme.breakpoints.up('sm')]: {
        width: theme.spacing(9),
      },
    }),
  },
}));

const SideDrawer = ({ open, toggleDrawer, onCourseCreated }) => {
  return (
    <Drawer variant="permanent" open={open}>
      <Toolbar sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between', // This spreads out the child elements
        px: [1]
            }}>
    <div style={{ flexGrow: 1 }} />  {/* This pushes the logo and button to the center and right respectively */}
    
    
    
</Toolbar>

      <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
        <List component="nav">
          <MainListItems />
          <Divider sx={{ my: 1 }} />
          <CourseListItems onCourseCreated={onCourseCreated} />
        </List>
        <Box sx={{ flexGrow: 1 }} />
        <List component="nav">
          <Divider sx={{ my: 1 }} />
          {secondaryListItems}
        </List>
      </Box>
    </Drawer>
  );
};

export default SideDrawer;
