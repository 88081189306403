import React, { useState, useEffect } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import ENDPOINTS from '../../utils/apiConfig';

export default function EditFileDialog({ fileId, open, onClose, onFileUpdated }) {
    const [fileName, setFileName] = useState('');

    const fetchFileDetails = async () => {
        const accessToken = localStorage.getItem('accessToken');
        try {
            const response = await fetch(`${ENDPOINTS.FILES}/${fileId}`, {
                headers: {
                    'Authorization': `Bearer ${accessToken}`,
                    'Content-Type': 'application/json'
                }
            });
            if (!response.ok) {
                throw new Error('Failed to fetch file details');
            }
            const data = await response.json();
            setFileName(data.file_name);  // Set the fetched name as initial state
        } catch (error) {
            console.error('Error fetching file details:', error);
        }
    };

    useEffect(() => {
        if (open) {
            fetchFileDetails();
        }
    }, [open, fileId]);

    const handleSave = async (event) => {
        event.preventDefault();
        const accessToken = localStorage.getItem('accessToken');
        try {
            const response = await fetch(`${ENDPOINTS.FILES}/${fileId}`, {
                method: 'PATCH',
                headers: {
                    'Authorization': `Bearer ${accessToken}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ file_name: fileName })
            });
            if (!response.ok) {
                throw new Error('Failed to update file name');
            }
            onClose();  // Close dialog on successful update
            onFileUpdated();  // Trigger file list refresh
        } catch (error) {
            console.error('Error updating file name:', error);
        }
    };

    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle>Dateinamen bearbeiten</DialogTitle>
            <form onSubmit={handleSave}>
                <DialogContent>
                    <TextField
                        autoFocus
                        margin="dense"
                        id="name"
                        label="Dateiname"
                        type="text"
                        fullWidth
                        variant="outlined"
                        value={fileName}
                        onChange={(e) => setFileName(e.target.value)}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={onClose} color="primary">
                        Abbrechen
                    </Button>
                    <Button type="submit" color="primary">
                        Speichern
                    </Button>
                </DialogActions>
            </form>
        </Dialog>
    );
}

