import React from 'react';
import { useNavigate } from 'react-router-dom';
import Button from '@mui/material/Button';

const AccountActivatedPage = () => {
  const navigate = useNavigate();

  const handleNavigateToLogin = () => {
    navigate('/login');
  };

  return (
    <div style={{ textAlign: 'center', marginTop: '50px' }}>
      <h1>JUHU! Dein Account konnte aktiviert werden</h1>
      <p>Jetzt kannst du einfach loslegen.</p>
      <Button variant="contained" onClick={handleNavigateToLogin} sx={{ marginTop: 2 }}>
        Ab zum Login
      </Button>
    </div>
  );
};

export default AccountActivatedPage;
