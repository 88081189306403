import React from 'react';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import DeleteQuestionSetDialog from '../../features/DeleteQuestionSetDialog/DeleteQuestionSetDialog';

export default function DeleteQuestionSetButton({ questionSetId, onQuestionSetUpdated }) {
    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <>
            <IconButton
                aria-label="delete"
                onClick={handleClickOpen}
                sx={{
                    width: 30, 
                    height: 30,
                    padding: 0,
                    color: 'grey', // Default color
                    '&:hover': {
                        color: 'black', // Color on hover
                        backgroundColor: 'transparent' // Optional: makes background transparent on hover
                    }
                }}
            >
                <DeleteIcon style={{ fontSize: '20px' }} />
            </IconButton>
            {open && <DeleteQuestionSetDialog open={open} onClose={handleClose} questionSetId={questionSetId} onQuestionSetUpdated={onQuestionSetUpdated} />}
        </>
    );
}
