import React from 'react';
import IconButton from '@mui/material/IconButton';
import AddCircleIcon from '@mui/icons-material/AddCircle';

export default function CreateQuestionButton({ onClick, sx }) {
    return (
        <IconButton
            aria-label="create"
            onClick={onClick}
            sx={{
                width: 25, 
                height: 25,
                padding: 0,
                mt: 2,
                color: 'grey', // Default color
                ...sx, // Accept additional styles
            }}
        >
            <AddCircleIcon style={{ fontSize: '25px' }} />
        </IconButton>
    );
}
