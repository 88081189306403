import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import Button from '@mui/material/Button';

const ActivationErrorPage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { error } = location.state || { error: "Activation failed due to an unexpected issue." }; // Default message

  const handleNavigateToHome = () => {
    navigate('/');
  };

  return (
    <div style={{ textAlign: 'center', marginTop: '50px' }}>
      <iframe src="https://giphy.com/embed/RWUqVYucDBD4A" width="480" height="317" frameBorder="0" class="giphy-embed" allowFullScreen></iframe>
      <h1>Oopsie! Da ist bei uns etwas ordentlich schief gelaufen.</h1>
      <h1>Dein Account konnte nicht aktiviert werden.</h1>
      <p>Vielleicht ist der Link schon abgelaufen?</p>
      <p>Versuche es gerne noch einmal oder wende dich an unseren Support</p>
      <Button variant="contained" onClick={handleNavigateToHome} sx={{ marginTop: 2 }}>
        Zur Startseite zurück
      </Button>
    </div>
  );
};

export default ActivationErrorPage;
