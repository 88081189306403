import ENDPOINTS from '../utils/apiConfig';
import { refreshToken } from '../utils/AuthenticationService'; // Ensure this is where you handle token fetching

export const fetchQuestionsByQuestionSet = async (questionSetId) => {
    let accessToken = localStorage.getItem('accessToken');
    const url = ENDPOINTS.GET_QUESTION_BY_QUESTIONSET(questionSetId);

    try {
        let response = await fetch(url, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${accessToken}`,
                'Content-Type': 'application/json'
            }
        });

        if (!response.ok) {
            if (response.status === 401) {
                // Access token might have expired, try refreshing it
                accessToken = await refreshToken();
                response = await fetch(url, {
                    method: 'GET',
                    headers: {
                        'Authorization': `Bearer ${accessToken}`,
                        'Content-Type': 'application/json'
                    }
                });
            }
            if (!response.ok) {
                const errorData = await response.json();
                console.error('API Error:', errorData);
                throw new Error(errorData.detail || 'Unknown API error');
            }
        }

        return await response.json();
    } catch (error) {
        console.error('Error fetching questions:', error);
        throw error;
    }
};
