// Adjust the import path according to your project structure
import ENDPOINTS from '../utils/apiConfig';
import { refreshToken } from '../utils/AuthenticationService'; // Ensure this is where you handle token fetching

export const fetchCourseFiles = async (courseId) => {
    let accessToken = localStorage.getItem('accessToken');
    const url = `${ENDPOINTS.FILES}/${courseId}`;

    try {
        let response = await fetch(url, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${accessToken}`,
                'Content-Type': 'application/json'
            }
        });

        if (!response.ok) {
            if (response.status === 401) {
                // Access token might have expired, try refreshing it
                accessToken = await refreshToken();
                response = await fetch(url, {
                    method: 'GET',
                    headers: {
                        'Authorization': `Bearer ${accessToken}`,
                        'Content-Type': 'application/json'
                    }
                });
            }
            if (!response.ok) {
                const errorData = await response.json();
                console.error('API Error:', errorData);
                throw new Error(errorData.detail || 'Unknown API error');
            }
        }

        return await response.json();
    } catch (error) {
        console.error('Error fetching files:', error);
        throw error;
    }
};

export const fetchFilesByCourse = async (courseId) => {
    let accessToken = localStorage.getItem('accessToken');
    const url = ENDPOINTS.COURSE_FILES(courseId); // Using the dynamic endpoint

    try {
        let response = await fetch(url, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${accessToken}`,
                'Content-Type': 'application/json'
            }
        });

        if (!response.ok) {
            if (response.status === 401) {
                // Access token might have expired, try refreshing it
                accessToken = await refreshToken();
                response = await fetch(url, {
                    method: 'GET',
                    headers: {
                        'Authorization': `Bearer ${accessToken}`,
                        'Content-Type': 'application/json'
                    }
                });
            }
            if (!response.ok) {
                const errorData = await response.json();
                console.error('API Error:', errorData);
                throw new Error(errorData.detail || 'Unknown API error');
            }
        }

        return await response.json();
    } catch (error) {
        console.error('Error fetching course files:', error);
        throw error;
    }
};

export const fetchFileDetails = async (fileId) => {
    let accessToken = localStorage.getItem('accessToken');
    const url = ENDPOINTS.GET_FILE(fileId); // Using the dynamic endpoint

    try {
        let response = await fetch(url, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${accessToken}`,
                'Content-Type': 'application/json'
            }
        });

        if (!response.ok) {
            if (response.status === 401) {
                // Access token might have expired, try refreshing it
                accessToken = await refreshToken();
                response = await fetch(url, {
                    method: 'GET',
                    headers: {
                        'Authorization': `Bearer ${accessToken}`,
                        'Content-Type': 'application/json'
                    }
                });
            }
            if (!response.ok) {
                const errorData = await response.json();
                console.error('API Error:', errorData);
                throw new Error(errorData.detail || 'Unknown API error');
            }
        }

        return await response.json();
    } catch (error) {
        console.error('Error fetching file details:', error);
        throw error;
    }
};
