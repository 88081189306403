import React, { useState, useEffect, useRef } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import ENDPOINTS from '../../utils/apiConfig';
import { Box } from '@mui/material';

export default function DeleteCourseDialog({ courseId, open, onClose, onCourseUpdated }) {
  const [courseName, setCourseName] = useState('');
  const cancelButtonRef = useRef(null); // Create a ref for the "Abbrechen" button

  // Function to fetch current course details
  const fetchCourseDetails = async () => {
    const accessToken = localStorage.getItem('accessToken');
    try {
      const response = await fetch(`${ENDPOINTS.COURSES}/${courseId}`, {
        headers: {
            'Authorization': `Bearer ${accessToken}`,
            'Content-Type': 'application/json'
        }
      });
      if (!response.ok) {
        throw new Error('Failed to fetch course details');
      }
      const data = await response.json();
      setCourseName(data.name);  // Set the fetched name as initial state
    } catch (error) {
      console.error('Error fetching course details:', error);
    }
  };

  // Effect to fetch course details when the dialog opens
  useEffect(() => {
    if (open) {
      fetchCourseDetails();
      // Set focus to the "Abbrechen" button when the dialog opens
      if (cancelButtonRef.current) {
        cancelButtonRef.current.focus();
      }
    }
  }, [open, courseId]);  // Depend on open and courseId to refetch when they change

  const handleDelete = async () => {
    const accessToken = localStorage.getItem('accessToken');
    try {
      const response = await fetch(`${ENDPOINTS.COURSES}/${courseId}`, {
        method: 'DELETE',
        headers: {
            'Authorization': `Bearer ${accessToken}`,
            'Content-Type': 'application/json'
        }
      });
      if (!response.ok) {
        throw new Error('Failed to delete course');
      }
      onClose();  // Close dialog on successful delete
      onCourseUpdated();  // Trigger course list refresh on MainPage
    } catch (error) {
      console.error('Error deleting course:', error);
    }
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Kurs löschen</DialogTitle>
      <DialogContent>
        <Typography variant="body1">
          Achtung! Wenn du den Kurs "{courseName}" löschst, gehen er und alle seine Inhalte unwiderruflich verloren. Diese Aktion kann nicht rückgängig gemacht werden.
        </Typography>
      </DialogContent>
      <DialogActions sx={{ justifyContent: 'space-between', padding: '0 24px 16px 24px' }}>
        <Box sx={{ display: 'flex', justifyContent: 'flex-start' }}>
          <Button onClick={handleDelete} color="error">
            Löschen
          </Button>
        </Box>
        <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Button onClick={onClose} color="primary" ref={cancelButtonRef}>
            Abbrechen
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
  
}
